@import 'variables';
body{
  .users-section{
    .users-header{
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
      margin: 15px;
      ul{
        float: right;
        li{
          margin-left: 15px;
          &.search-fields{
          width:250px;  
          position: relative;
          span.user-search-close{
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
          }
        }
        }
      }
    }
    .user-card{
      background: #F1F1F1;
      padding: 15px;
      height: 160px;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0,0,0,.25);
      position: relative;
      &:hover{
        ul{
          display: inline-block;
        }
      }
      ul{
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        li{
          margin-left: 6px;
          cursor: pointer;
        }
      }
      .user-pic{
        display: inline-block;
        margin: 0 auto;
        margin-bottom: 10px;
        position: relative;
        .account-status{
          position: absolute;
          bottom: -3px;
          left: -3px;
        }
      }
      .user-name-dsi{
        margin-bottom: 15px;
        h3{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #000000, $margin:0 0 4px 0);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
      }
      .user-other-info{
        h6{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000, $margin:0 0 3px 0);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.7));
        }
      }
    }
    .infinite-scroll-component__outerdiv{
      .infinite-scroll-component{
        overflow-x: hidden!important;
      }
    }
  }
  .roles-section{
    .heading{
      margin: 15px;
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
    }
  }
  .edit-user-role{
    h2{
      border-bottom: 1px solid #d7d7d7;
      padding: 10px;
    }
    .user-info{
      background: #f2f2f2;
      padding: 10px;
      span{
        font-size: 13px;
      }
    }
    .user-role{
      padding: 10px;
      margin-top: 10px;
    }
    ul{
      li{
        margin-right: 10px;
      }
    }
  }
}
