@import 'variables';
body{
    .portfolio-fleet-section{
        .go-back-icon{
            margin-top: 2px;
            font-size: 13px;
            display: flex;
            margin-bottom: 10px;
            color: rgb(189, 17, 224);
            width: max-content;
            font-weight: 600;
            cursor: pointer;
        }
        .MuiTabs-scroller{
            margin-bottom: 0 !important;
        }
        .fleet-tabs{
            ul{
                li{
                    margin-left: 6px;
                }
            }
        }
        .fleet-filter-div{
            margin-top: 10px;
            padding: 6px 10px;
            ul{
                float: right;
                li{
                    margin-left: 6px;
                }
            }
            .cal-view-heading{
                margin: 4px;
                font-weight: 600;
                margin-bottom: 20px;
                .cal-toggle-sw{
                        border: 1px solid #d7d7d7;
                        display: flex;
                        align-items: center;
                        border-radius: 3px;
                        width: 122px;
                        background: #EEE;
                        // margin-bottom: 10px;
                        margin-left: auto;
                        span{
                          width: 75px;
                          padding:3px 5px;
                          text-align: center;
                          cursor: pointer;
                          &.active{
                            font-weight: 600;
                            background: #4050b5;
                            color: #EEE;
                          }
                        }
                }
            }
            .cal-view-block{
                height: 200px;
                .cal-card-date{
                    // font-family: sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    border-top: 1px solid #ebebeb;
                    color: #000;
                    padding: 5px 7px;
                    // background-color: #b5bedb;
                }
                .cal-card-content{
                    overflow: auto;
                    height: 159px;
                    padding: 3px 6px;
                    .cal-table-row{
                        font-family: $ibmplexRegular;
                        padding: 4px;
                        color:rgba(0, 0, 0, 0.87);
                        background-color: #f6f8ff;
                        font-size:  15px;
                        &:nth-child(even){
                            background: #fff;
                          }
                          &:hover{
                              background-color: #e7ebf9;
                        }
                    }
                    .cal-view-table-ui{
                        min-width: none !important;
                    }
                }
            }
        }
    }
    .fleet-log-section{
        //max-height: calc(100vh - 290px);
    }
        .fleet-log-section th:last-child,
        .fleet-log-section td:last-child {
            position: sticky;
            right: 0;
            z-index: 3;
        }
        .engine-log-list th:nth-child(1),
        .engine-log-list td:nth-child(1){
            position: sticky;
            left: 0;
            z-index: 3;
            width: 55px;
        }
        .engine-log-list td:nth-child(2),
        .engine-log-list th:nth-child(2){
            position: sticky;
            left: 58px;
            width: 100px;
            z-index: 3;
        }
        .engine-log-list td:nth-child(3),
        .engine-log-list th:nth-child(3){
            position: sticky;
            left: 178px;
            width: 100px;
            z-index: 3;
            padding-right: 28px;
        }
        
        .engine-log-list {
            thead{
                tr{
                    th:nth-child(3) {
                        // width: 65px;
                    }
                    th:last-child {
                        width: 65px;
                    }
                }
            }
            tbody {
                tr:nth-child(odd) {
                    td:nth-child(2), td:nth-child(1) {
                        background: #ffffff;
                    }
                    td:nth-child(3) {
                        background: #ffffff;
                    }
        
                    &:hover {
                        td:nth-child(2), td:nth-child(1){
                            background: #e6ebfb;   
                           }
                        td:nth-child(3){
                            background: #e6ebfb;
                        }
                    }
                    td:last-child {
                        background: #ffffff;
                    }
        
                    &:hover {
                        td:last-child {
                            background: #e6ebfb;
                        }
                    }
                }
        
                tr:nth-child(even) {
                    td:nth-child(2), td:nth-child(1) {
                        background: #f6f8ff;
                    }
                    td:nth-child(3){
                        // background: linear-gradient(270deg, #f6f8ff 1%, #f3f3f3cc 12%, #f6f8ff 17%)
                        background: #f6f8ff;
                    }
        
                    &:hover {
                        td:nth-child(2), td:nth-child(1){
                         background: #e6ebfb;   
                        }
                        td:nth-child(3) {
                            background: #e6ebfb;
                        }
                    }
                    td:last-child {
                        background: #f6f8ff;
                    }
        
                    &:hover {
                        td:last-child {
                            background: #e6ebfb;
                        }
                    }
                }
            }
        }
        }
        .compare-log-width{
            min-width: 800px;
        }
        @media only screen and (max-width: 932px) and (min-width: 320px){
            body{
            .fleet-log-section{
               // max-height: calc(100vh - 340px);
            }
            .fleet-log-section th:last-child,
            .fleet-log-section td:last-child {
                position:sticky;
            }
            .engine-log-list th:nth-child(1),
            .engine-log-list td:nth-child(1){
                position: sticky;
            }
            .engine-log-list td:nth-child(2),
            .engine-log-list th:nth-child(2){
                position: static;
            }
            .engine-log-list td:nth-child(3),
            .engine-log-list th:nth-child(3){
                position: static;
                // left: 178px;
                // width: 100px;
                // z-index: 3;
                // padding-right: 28px;
            }
        }
        .compare-log-width{
            min-width: unset !important;
            padding: 4px 6px !important;
        }
        }