@import 'variables';

body {
  .audit-log-section {
    .audit-title{
      margin-bottom: 16px;
      h1{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
      p{
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #838383);
      }
    }
  }
}
