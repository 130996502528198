@import 'variables';
body{
  .MuiInputBase-root{
    // font-size: 15px !important;
  }
  .MuiOutlinedInput-multiline{
    padding: 0;
  }
  .MuiTabs-scroller{
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }
  .MuiTabs-scrollable{
    margin-bottom: 20px !important;
  }
  .MuiTextField-root label {
      font-size: 16px;
      color: #000;
      font-family: "Conv_IBMPlexSans-Medium" !important;
  }
  .MuiOutlinedInput-input{
    padding: 14px!important;
  }
  .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
    padding: 0!important;
  }
  .MuiTooltip-tooltip{
    font-size: 12px;
  }
  .MuiButton-containedPrimary{
    font-size: 13px;
  }
  .MuiDialog-paper{
    .MuiDialogTitle-root{
      padding: 10px 24px;
      h2{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);
      }
    }
  }
  @media only screen and (max-width: 667px){
    .MuiDialog-paper{
      margin: 8px !important;
    }
    .MuiTabs-scrollButtons{
      width: 20px !important;
    }
    .MuiDialog-paperWidthSm{
      min-height: 300px !important;
    }
  }
  @media only screen and (orientation: landscape){
    .MuiDialog-paper{
      margin: 8px !important;
      min-height: 220px !important;
    }
  }
}
